// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

import './best_in_place'

function activateBestInPlace() {
    $(".best_in_place").best_in_place();
}

function intentLookup() {
    var input, filter, i;
    input = document.getElementById("search_in");
    filter = input.value.toUpperCase();
    for (i = 0; i < window.intent_total; i++) {
        let current = document.getElementById("item"+i);
        let currentLabel = document.getElementById("item_label"+i)
        if(currentLabel) {
            if (currentLabel.innerHTML.trim().toUpperCase().indexOf(filter) > -1) {
                current.style.display = "";
              } else {
                current.style.display = "none";
            }
        }
    }

  }

$(document).ready(function() {
    activateBestInPlace();
})

window.activateBestInPlace = activateBestInPlace;
window.intentLookup = intentLookup;

import "./styles.css"
require('./nested-forms/addFields')
require('./nested-forms/removeFields')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
import "controllers"
